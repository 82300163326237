/* styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* styles/global.css */
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #F9FaFd; /* Dark green color */
  color: #36454f;
  font-family: 'Montserrat';
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.items-start {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.min-h-screen {
  min-height: 100vh;
}

.table-auto {
  width: auto;
  border-collapse: collapse;
}

.w-full {
  width: 100%;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-4 {
  padding: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-0 {
  margin-top: 0;
}

.md\:ml-6 {
  margin-left: 1.5rem;
}

.md\:mt-0 {
  margin-top: 0;
}

.overflow-auto {
  overflow: auto;
}

.rounded {
  border-radius: 0.25rem;
}

.bg-green-500 {
  background-color: #48bb78;
}

.bg-green-700 {
  background-color: #2f855a;
}

.bg-blue-500 {
  background-color: #4299e1;
}

.bg-blue-700 {
  background-color: #2b6cb0;
}

.hover\:bg-green-700:hover {
  background-color: #2f855a;
}

.hover\:bg-blue-700:hover {
  background-color: #2b6cb0;
}

.horizontal-button-row {
  display: flex;
  justify-content: space-around;
}

.page-header {
  padding: 12px;
}

.horizontal-button-row button {
  min-width: 100px;
  color: white;
}

.text-white {
  color: white;
}

.game-board {
  min-height: 450px;
  flex-direction: column;
}

.game-board .actor-hand {
  display: flex;
}

.timer-header {
  text-align: left;
  font-size: 36px;
  position: fixed;
  top: 24px;
  left: 24px;
}

.cards-in-play {
  justify-content: center;
}

.cards-in-play.cards-sm img {
  width: 60px;
}
.cards-in-play.cards-lg img {
  width: 100px;
}

.hero {
  max-width: 500px;
  min-height: 100vh;
  margin: auto;
  align-items: center;
}

.felt-panel {
  width: 900px;
}

.chips-btn {
  border-radius: 50%;
  height: 75px;
  width: 75px;
  margin: 0 12px;
}

.chips-btn.chip-yellow {
  background-color: rgb(141, 141, 0);
}

.chips-btn.chip-blue {
  background-color: rgb(0, 0, 147);
}

.chips-btn.chip-black {
  background-color: rgb(32, 32, 32);
}

.chips-btn.chip-purple {
  background-color: rgb(90, 0, 90);
}

.chips-btn.chip-white {
  background-color: #F9FaFd;
  color: #36454f;
  border: 2px solid 
}

.deal-btn {
  background-color: green;
  width: 125px;
  height: 50px;
}

.stack-count {
  margin-top: 24px;
}

.action-btn {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 18px;
}

.chip-gain {
  color: green;
}

.chip-loss {
  color: red;
}

.strategy-recs {
  position: fixed;
  border: 4px solid black;
  bottom: 400px;
  left: 12px;
  padding: 20px;
  width: 200px;
}

.strategy-recs .rec {
  text-align: right;
}

.leaderboard-page {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.leaderboard-table-section {
  width: 600px;
  text-align: center;
}

.leaderboard-page table {
  width: 100%;
}

.leaderboard-table td {
  padding: 6px 0;
}

.background-primary {
  background-color: #f3cd74;
}

.anchor-button {
  display: flex;
  align-items: center;
  justify-content: center; /* Optional: also centers text horizontally */
  text-decoration: none;
  position: fixed;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.new-round-btn {
  left: 12px;
  bottom: 12px;
  background-color: green;
  color: white;
}

.new-player-btn {
  left: 12px;
  bottom: 150px;
  background-color: #f3cd74;
  color: #36454f;
}

.ai-stats-section {
  width: 500px;
  text-align: center;
}

.ai-stats-section table {
  font-size: 24px;
}

.ai-stats-section tbody tr {
  border-color: lightgray;
  border-top: 1px;
  border-style: solid;
}

.ai-stats-section td {
  padding: 10px 0;
}

.ai-stats-section td.good-stat {
  color: green;
}
.ai-stats-section td.bad-stat {
  color: red;
}

.done-btn {
  background-color: rgb(130, 9, 9);
}
